<script setup>
import { onBeforeUnmount, onMounted, watch } from "vue"
import Intercom from "@intercom/messenger-js-sdk"
import { useAuth } from "@/stores/auth"

const authStore = useAuth()

onMounted(() => {
  if (authStore.user) {
    Intercom({
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      email: authStore.user.email,
      name: authStore.user.first_name,
      user_hash: authStore.intercomToken,
      user_id: authStore.user.email
    })
  }
  watch(
    () => authStore.user,
    (newUser) => {
      if (newUser) {
        Intercom({
          app_id: import.meta.env.VITE_INTERCOM_APP_ID,
          email: authStore.user.email,
          name: authStore.user.first_name,
          user_hash: authStore.intercomToken,
          user_id: authStore.user.email
        })
      } else {
        Intercom("shutdown")
      }
    }
  )
})

onBeforeUnmount(() => {
  Intercom("shutdown")
})
</script>

<template>
  <div></div>
</template>

<style scoped>

</style>