<script setup>
import { RouterView } from "vue-router"
import { ModalsContainer } from "vue-final-modal"
import { useAuth } from "@/stores/auth"
import AppToast from "@/components/overlays/AppToast.vue"
import IntercomWrapper from "@/components/IntercomWrapper.vue"
import TheLoader from "@/components/TheLoader.vue"
document.documentElement.setAttribute("data-theme", "light")

const authStore = useAuth()
</script>

<template>
  <div class="relative flex flex-row flex-1">
    <ModalsContainer />
    <RouterView name="sidebar" />
    <div class="flex flex-col flex-1">
      <RouterView name="header" />
      <main class="flex-1">
        <section class="h-full mx-auto my-0">
          <RouterView v-slot="{ Component }">
            <Suspense timeout="0">
              <template #default>
                <Component :is="Component" />
              </template>
              <template #fallback>
                <TheLoader />
              </template>
            </Suspense>
          </RouterView>
        </section>
        <IntercomWrapper v-if="authStore.accountType === 2" />
        <RouterView name="bottombar" />
      </main>
    </div>
    <AppToast />
  </div>
</template>
