import { createApp, nextTick } from "vue"
import { createPinia } from "pinia"

import App from "./App.vue"
import router from "./router"

import posthog from "posthog-js"
import * as Sentry from "@sentry/vue"
import { setLocale } from "yup"
import { createVfm } from "vue-final-modal"
import VueAwesomePaginate from "vue-awesome-paginate"

import { clickOutside } from "@/directives/clickOutside"
import VWave from "v-wave"

import "./assets/base.css"
import "@vueform/multiselect/themes/tailwind.css"
import "vue-final-modal/style.css"

/* Yup Locale */
setLocale({
    mixed: {
        required: "Champ requis",
        notType: "Valeur invalide",
        notNull: "Champ requis",
    },
    object: {
        required: "Champ requis",
        notType: "Valeur invalide",
    },
    string: {
        email: "Adresse e-mail invalide"
    },
    number: {
        min: "Valeur invalide",
        max: "Valeur invalide",
        positive: "La valeur ne peut être négative ou nulle"
    }
})

const app = createApp(App)

if (import.meta.env.VITE_APP_NODE_ENV === "production") {
    posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
        api_host: "https://eu.posthog.com",
        capture_pageview: false
    })
    app.provide("posthog", posthog)
}


Sentry.init({
    app,
    dsn: "https://7348f92311e6fa4a2ddb00066b873665@o4506593304051712.ingest.us.sentry.io/4506718446026752",

    environment: import.meta.env.VITE_APP_NODE_ENV,

    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
            networkDetailAllowUrls: ['https://api.tucommencesdemain.fr/api/v1/administrative/training-convention/validation'],
            networkRequestHeaders: [''],
            networkResponseHeaders: ['']
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/app-staging\.tucommencessdemain\/.fr/,
        /^https:\/\/app\.tucommencessdemain\/.fr/
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

const vfm = createVfm()
app.directive("click-outside", clickOutside)

app.use(createPinia())
app.use(router)
app.use(vfm)
app.use(VWave)
app.use(VueAwesomePaginate)

app.mount('#app')

router.afterEach((to, from, failure) => {
    if (import.meta.env.VITE_APP_NODE_ENV === "production" && !failure) {
        nextTick(() => {
            posthog.capture("$pageview", { path: to.fullPath })
        })
    }
})

