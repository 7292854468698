import axios from "axios"
import router from "@/router"
import { useAuth } from "@/stores/auth"
import { showToast } from "@/composables/useToast"

const useAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true
})

useAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

useAxios.interceptors.response.use(
  (response) => {
    if (response.config.method !== 'get') {
      showToast(response.data.message, response.data.state)
    }
    return response
  },
  async (error) => {
    const originalConfig = error.config
    const authStore = useAuth()

    if (error.response) {
      if (error.response.status === 401) {
        if (originalConfig.url.includes('refresh')) {
          await authStore.$reset()
          await router.push('/login')
          return Promise.reject(error)
        }

        if (!originalConfig._retry && authStore.getIsAuthenticated) {
          originalConfig._retry = true
          try {
            await authStore.refreshTokenAction()
            return useAxios(originalConfig)
          } catch (refreshError) {
            await authStore.$reset()
            await router.push('/login')
            return Promise.reject(refreshError)
          }
        }
      }
      showToast(error.response.data.message, error.response.data.state)
    } else {
      showToast("Une erreur s'est produite", "error")
    }

    return Promise.reject(error)
  }
)

export default useAxios